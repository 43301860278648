import {
  Button,
  EmptyStateCard,
  Icon,
  IconNames,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AddAccountModal from '@src/containers/Orgs/Accounts/components/AddAccountModal/AddAccountModal';
import { SectionTopBarSearchField } from '@src/containers/Orgs/components/SectionTopBarWrapper';
import SectionsSettingWrapper from '@src/containers/Orgs/components/styles';
import useResourceAccountsQuery from '@src/hooks/react-query/resource-accounts/queries/useResourceAccountsQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';

import { TestAccountModal } from './components/TestAccountModal/TestAccountModal';

export interface AccountOption {
  name: string;
  id: ResourceAccountType;
  icon: IconNames;
  testingId?: string;
  onClick: () => void;
  hide?: boolean;
}

const AccountsTable = styled(WalTable)`
  margin-bottom: ${units.margin.md};
`;

export const Wrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${units.padding.xxl};
`;

export const getResourceAccountTypeText = (
  type: ResourceAccountType,
  translations: any
): string => {
  switch (type) {
    case 'aws':
      return translations.AWS;
    case 'aws-role':
      return translations.AWS_ROLE;
    case 'gcp':
      return translations.GCP;
    case 'gcp-identity':
      return translations.GCP_WORKLOAD_IDENTITY;
    case 'azure':
      return translations.AZURE;
    case 'azure-identity':
      return translations.AZURE_IDENTITY;
    case 'aiven':
      return translations.AIVEN;
    case 'cloudflare':
      return translations.CLOUDFLARE;
    default:
      return '';
  }
};

const Accounts = () => {
  // React Query
  const { data: resourceAccounts = [], isFetched: accountsLoaded } = useResourceAccountsQuery();
  // Component state
  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [accountTypeToAdd, setAccountTypeToAdd] = useState<ResourceAccountType | undefined>();
  const [editingAccount, setEditingAccount] = useState<ResourceAccount | undefined>();

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const accountsTranslations = t('ACCOUNT_SETTINGS').ACCOUNTS;

  // Zustand
  const { lastCreatedResourceAccountIds } = useResourcesStore();

  // context
  const { orgRole } = useGetUserRoles();

  const [accountsFilterString, setAccountsFilterString] = useState<string>('');

  const filteredAccounts = useMemo(() => {
    return resourceAccounts.filter((resourceAccount) =>
      resourceAccount.name.toLowerCase().includes(accountsFilterString.toLowerCase())
    );
  }, [accountsFilterString, resourceAccounts]);

  const editAccount = (account: ResourceAccount) => {
    setEditingAccount(account);
    setAccountModalOpen(true);
  };

  const accountColumns: WalTableColumn<ResourceAccount>[] = [
    {
      label: accountsTranslations.ACCOUNT_NAME,
      prop: 'name',
      template: (row) => {
        const accountType =
          row.data.type === 'aws-role'
            ? 'aws'
            : row.data.type === 'gcp-identity'
              ? 'gcp'
              : row.data.type === 'azure-identity'
                ? 'azure'
                : row.data.type;
        return (
          <span className={'flex-centered'}>
            <Icon size={20} name={accountType} marginRight={'md'} />
            {row.data.name}
          </span>
        );
      },
    },
    {
      label: accountsTranslations.ACCOUNT_TYPE,
      prop: 'type',
      template: (row) => (
        <span>{getResourceAccountTypeText(row.data.type, accountsTranslations)}</span>
      ),
    },
    {
      label: accountsTranslations.IN_USE,
      prop: 'is_used',
      justifyContent: 'center',
      template: (row) => (row.data.is_used ? <Icon name={'checkmark'} /> : <span>-</span>),
    },
    {
      label: accountsTranslations.CREATED_AT,
      prop: 'created_at',
      template: (row) => (
        <span>
          {row.data.is_default
            ? '...'
            : formatDate(row.data.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        </span>
      ),
    },
    {
      prop: 'actions',
      hide: orgRole !== 'administrator',
      template: (row) =>
        !row.data.is_default && (
          <>
            <TestAccountModal resourceAccountId={row.data.id} />
            <Button
              iconLeft={'edit'}
              onClick={() => editAccount(row.data)}
              size={'small'}
              variant={'secondary'}
              ariaLabel={uiTranslations.EDIT}
            />
          </>
        ),
    },
  ];

  const handleAccountOptionClick = (id: ResourceAccountType) => {
    setAccountTypeToAdd(id);
    setAccountModalOpen(true);
  };

  const accountOptions: AccountOption[] = [
    {
      name: accountsTranslations.GCP,
      id: 'gcp',
      icon: 'gcp',
      testingId: 'qa-gcp-button',
      onClick: () => handleAccountOptionClick('gcp'),
    },
    {
      name: accountsTranslations.GCP_WORKLOAD_IDENTITY,
      id: 'gcp-identity',
      icon: 'gcp',
      onClick: () => handleAccountOptionClick('gcp-identity'),
    },
    {
      name: accountsTranslations.AWS,
      id: 'aws',
      icon: 'aws',
      onClick: () => handleAccountOptionClick('aws'),
    },
    {
      name: accountsTranslations.AWS_ROLE,
      id: 'aws-role',
      icon: 'aws',
      onClick: () => handleAccountOptionClick('aws-role'),
    },
    {
      name: accountsTranslations.AIVEN,
      id: 'aiven',
      icon: 'aiven',
      onClick: () => handleAccountOptionClick('aiven'),
    },
    {
      name: accountsTranslations.AZURE,
      id: 'azure',
      icon: 'azure',
      onClick: () => handleAccountOptionClick('azure'),
    },
    {
      name: accountsTranslations.AZURE_IDENTITY,
      id: 'azure-identity',
      icon: 'azure',
      onClick: () => handleAccountOptionClick('azure-identity'),
    },
    {
      name: accountsTranslations.CLOUDFLARE,
      id: 'cloudflare',
      icon: 'cloudflare',
      onClick: () => handleAccountOptionClick('cloudflare'),
    },
  ];

  return (
    <SectionsSettingWrapper
      rightContent={
        resourceAccounts.length > 1 && (
          <SectionTopBarSearchField
            name={'filter-accounts'}
            placeholder={accountsTranslations.ACCOUNTS_FILTER_PLACEHOLDER}
            onChange={setAccountsFilterString}
          />
        )
      }>
      <Wrapper>
        <Button iconLeft={'plus'} onClick={() => setAccountModalOpen(true)}>
          {accountsTranslations.ADD_ACCOUNT}
        </Button>
      </Wrapper>

      {filteredAccounts.length === 0 && accountsLoaded ? (
        <EmptyStateCard>{accountsTranslations.NO_CLOUD_ACCOUNTS}</EmptyStateCard>
      ) : (
        <AccountsTable
          caption={accountsTranslations.TABLE_CAPTION}
          columns={accountColumns}
          disableScrolling
          rows={
            filteredAccounts
              ? filteredAccounts?.map(
                  (resourceAccount): WalTableRow => ({
                    data: resourceAccount,
                    isNew: lastCreatedResourceAccountIds.includes(resourceAccount.id),
                  })
                )
              : []
          }
          showCount
        />
      )}
      {accountModalOpen && (
        <AddAccountModal
          isAccountPage
          accountOptions={accountOptions.filter((accountOption) => !accountOption.hide)}
          className={'qa-provider-modal'}
          key={editingAccount?.id}
          editingAccountState={[editingAccount, setEditingAccount]}
          openState={[accountModalOpen, setAccountModalOpen]}
          accountType={editingAccount ? editingAccount.type : accountTypeToAdd}
        />
      )}
    </SectionsSettingWrapper>
  );
};

export default Accounts;
